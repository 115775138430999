<template>
    <div class="detailed-company-card">
        <div class="detailed-company-card__header" v-if="company.id">
            <div class="detailed-company-card__company">
                <img v-if="company.logo" :src="baseUrl + company.logo" class="detailed-company-card__logo" alt="company">
                <img v-else src="@/assets/images/empty-company-logo.svg" class="detailed-company-card__logo" alt="company">
                <div class="detailed-company-card__company-info">
                    <h3 class="detailed-company-card__title">
                        {{ company.name }}
                    </h3>

                    <div class="detailed-company-card__company-date-wrapper">
                        <p class="detailed-company-card__company-date">{{ dateText }} </p>
                        <VTooltip
                            text="Срок договора истекает"
                            class="detailed-company-card__tooltip"
                            v-if="days > 0 && days < 30 && startDate < 0"
                        >
                            <template #icon>
                                <img src="@/assets/images/icons/tooltip-gray-fill.svg" alt="tooltip"/>
                            </template>
                        </VTooltip>
                    </div>
                    <div class="detailed-company-card__company-status" v-if="company.has_contract">Активно</div>
                    <div class="detailed-company-card__company-status detailed-company-card__company-status_disabled" v-else>
                        Заблокировано
                    </div>
                </div>
            </div>

<!--            <VButton-->
<!--                label="Редактировать компанию"-->
<!--                autoSize-->
<!--                bg="transparent"-->
<!--                bgHover="#F4F5F7"-->
<!--                color="#0D5FCB"-->
<!--                colorHover="#0D5FCB"-->
<!--                link-->
<!--                :to="`/companies/${company.id}/edit-company`"-->
<!--                class="detailed-company-card__btn">-->
<!--                <template #iconLeft>-->
<!--                    <img src="@/assets/images/icons/pencil.svg" alt="pencil">-->
<!--                </template>-->
<!--            </VButton>-->
        </div>

        <div class="detailed-company-card__content" v-if="company.id">
            <div class="detailed-company-card__title detailed-company-card__content-title">Идентификаторы компании:</div>
            <div class="detailed-company-card__ids">
                <div class="detailed-company-card__id">
                    <p class="detailed-company-card__id-name">ИНН:</p>
                    <div class="detailed-company-card__id-value">{{company?.inn}}</div>
                </div>
                <div class="detailed-company-card__id">
                    <p class="detailed-company-card__id-name">ОГРН:</p>
                    <div class="detailed-company-card__id-value">{{company?.ogrn}}</div>
                </div>
            </div>

            <div class="detailed-company-card__title detailed-company-card__content-title">Описание компании:</div>
            <div class="detailed-company-card__description"
                 :class="{'detailed-company-card__description_more': showMore, 'detailed-company-card__description_hidden': descriptionHeight > 60}"
                 ref="description">
                <p v-html="company.description" class="detailed-company-card__description-html"></p>
            </div>
            <span class="detailed-company-card__description-more"
                  @click="showMore = true"
                  v-if="descriptionHeight > 70 && !showMore">Смотреть еще...</span>
        </div>

        <div class="detailed-company-card__access" v-if="company.id">
            <div class="detailed-company-card__title-setting">Настройки:</div>

            <div class="detailed-company-card__access-cards">
                <div class="detailed-company-card__access-card">
                    <div class="detailed-company-card__access-card-title">Расширенная анкета</div>
                    <div class="detailed-company-card__access-card-status" v-if="!company.access_extended_form">
                        Не включено
                    </div>
                    <div class="detailed-company-card__access-card-status detailed-company-card__access-card-status_active"
                         v-else>
                        Включено
                    </div>
                </div>

<!--                <div class="detailed-company-card__access-card">-->
<!--                    <div class="detailed-company-card__access-card-title">Проверка на благонадёжность</div>-->
<!--                    <div class="detailed-company-card__access-card-status" v-if="!company.verification_of_loyalty">-->
<!--                        Не включено-->
<!--                    </div>-->
<!--                    <div class="detailed-company-card__access-card-status detailed-company-card__access-card-status_active"-->
<!--                         v-else>-->
<!--                        Включено-->
<!--                    </div>-->
<!--                </div>-->

                <div class="detailed-company-card__access-card">
                    <div class="detailed-company-card__access-card-title">Чаты</div>
                    <div class="detailed-company-card__access-card-status" v-if="!company.chats_for_approving_person">
                        Не включено
                    </div>
                    <div class="detailed-company-card__access-card-status detailed-company-card__access-card-status_active"
                         v-else>
                        Включено
                    </div>
                </div>

                <div class="detailed-company-card__access-card">
                    <div class="detailed-company-card__access-card-title">Этап Билеты</div>
                    <div class="detailed-company-card__access-card-status" v-if="!company.activation_tickets_stage">
                        Не включено
                    </div>
                    <div class="detailed-company-card__access-card-status detailed-company-card__access-card-status_active"
                         v-else>
                        Включено
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, onMounted, computed} from "vue";
import moment from "moment";

const props = defineProps({
    company: {
        type: Object,
        required: true
    }
})

const baseUrl = process.env.VUE_APP_BASE;

const showMore = ref(false)
const description = ref(null)
const descriptionHeight = ref(0)

onMounted(() => descriptionHeight.value = description.value.offsetHeight)

const startDate = moment(props.company.contract_date_from).diff(moment(), "hours");
const days = moment(props.company.contract_date_to).add(1, 'd').diff(moment(), "days");
const date = moment(props.company.contract_date_to).format("DD.MM.YYYY");

const createLabel = (number, titles) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
}

const dateText = computed(() => {
    if (startDate > 0) return `Срок договора начнется с ${moment(props.company.contract_date_from).format("DD.MM.YYYY")}`
    if (days > 0) return `${date} (ост. ${days} ${createLabel(days, ['день', 'дня', 'дней'])})`;
    return "Срок договора истёк";
});
</script>

<style scoped lang="scss">
.detailed-company-card {
    width: 100%;
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0 #0000000A;

    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__title {
        font-size: 20px;
        font-weight: 600;
        max-width: 300px;
        display: flex;
    }

    &__title-setting {
        font-size: 14px;
        font-weight: 600;
    }

    &__content-title {
        font-size: 14px;
        font-weight: 600;
    }

    &__ids {
        margin-top: 12px;
        margin-bottom: 24px;
        display: flex;
    }

    &__id {
        display: flex;

        &:not(:first-child) {
            margin-left: 44px;
        }
    }

    &__id-name {
        color: $gray;
        font-size: 14px;
        font-weight: 400;
    }

    &__id-value {
        margin-left: 12px;
        color: $dark;
        font-size: 14px;
        font-weight: 400;
    }

    &__tooltip {
        height: 17px;
        margin-left: 8px;
    }

    &__company {
        display: flex;
        align-items: center;
    }

    &__logo {
        width: 64px;
        height: 64px;
        border-radius: 8px;
        object-fit: contain;
        border: 1px solid $light-gray-1;
    }

    &__company-info {
        margin-left: 16px;
        padding-top: 3px;
    }

    &__company-date-wrapper {
        margin-top: 4px;
        display: flex;
        align-items: center;
    }

    &__company-date {
        color: #566A83;
        font-size: 14px;
        font-weight: 400;
    }

    &__company-status {
        display: none;
        margin-top: 8px;
        color: $blue;
        font-size: 12px;
        font-weight: 500;

        &_disabled {
            color: $red;
        }
    }

    &__btn {
        height: 32px;
        padding: 0 8px;
        margin-right: -11px;
        font-size: 14px;
        font-weight: 500;

        &:active {
            background-color: $light-gray-1 !important;
        }
    }

    &__content {
        margin-top: 24px;
    }

    &__description {
        position: relative;
        max-width: 670px;
        margin-top: 12px;
        font-size: 14px;
        font-weight: 400;

        &_hidden {
            -webkit-line-clamp: 4;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        &_more {
            -webkit-line-clamp: unset;
            overflow: visible;
        }
    }

    &__description-html {
        font-size: 14px !important;
        &::v-deep(li) {
            font-size: 14px !important;
            margin-left: 24px;
            line-height: 140%;
        }
    }

    &__description_more &__description-more {
        display: none;
    }

    &__description-more {
        display: block;
        margin-top: 8px;
        color: $blue;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;

        &:hover {
            color: #1E9AC6;
        }

        &:active {
            color: #006894;
        }
    }

    &__access {
        margin-top: 24px;
    }

    &__access-cards {
        margin-top: 12px;
        margin-left: -24px;
        display: flex;
    }

    &__access-card {
        height: 44px;
        padding: 0 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:not(:first-child) {
            border-left: 1px solid $light-gray-1;
        }
    }

    &__access-card-title {
        color: $dark;
        font-size: 14px;
        font-weight: 400;
    }

    &__access-card-status {
        margin-top: 4px;
        color: $light-gray-2;
        font-size: 12px;
        font-weight: 500;

        &_active {
            color: $dark-blue;
            font-size: 12px;
            font-weight: 500;
        }
    }
}
</style>
