<template>
    <div class="company" v-if="company.id">
        <div class="company__wrapper">
            <InternalHeader title="Карточка компании" class="company__header"/>
            <div class="company__block-wrapper">
                <div class="container">
                    <DetailedCompanyCard class="company__card" :company="company"/>
                </div>
                <div class="company__block-menu">
                    <div class="company__block-menu-title">Вы можете:</div>
                    <router-link :to="`/companies/${company.id}/edit-company`" class="company__block-menu-btn">
                        <img src="../../assets/images/icons/pencil.svg" alt="pencil">
                        Редактировать
                    </router-link>
                </div>
            </div>
        </div>

        <div class="company__employees">
            <div class="company__employees-header">
                <h2 class="company__employees-title">Сотрудники компании <span
                    v-if="company.employees.length">{{ company.employees.length }}</span></h2>
                <VButton
                    label="Новый сотрудник"
                    autoSize
                    bg="transparent"
                    bgHover="#E4E7EE"
                    color="#0086B2"
                    colorHover="#0086B2"
                    link
                    :to="`/companies/${company.id}/create-employee`"
                    class="company__employees-new">
                    <template #iconLeft>
                        <img src="@/assets/images/icons/plus-blue.svg" alt="pencil">
                    </template>
                </VButton>
            </div>

            <div class="company__employees-cards">
                <div class="company__employees-cards-empty" v-if="!company.employees.length">
                    <h3 class="company__employees-cards-empty-title">Список сотрудников пуст</h3>
                    <p class="company__employees-cards-empty-description">В этой компании пока нет сотрудников</p>
                </div>
                <EmployeeCard
                    class="company__employees-card"
                    v-for="employee in company.employees"
                    :key="employee.id"
                    :employee="employee"
                    :companyId="company.id"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onActivated, onDeactivated, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import companies from "@/api/companies";

import InternalHeader from "@/components/InternalHeader";
import DetailedCompanyCard from "@/components/DetailedCompanyCard";
import EmployeeCard from "@/components/EmployeeCard";

const route = useRoute();

const company = ref({})
const getCompany = async () => {
    try {
        company.value = await companies.getCompany(route.params.id)
    } catch (error) {
        console.log(error)
    }
}

onActivated(() => {
    getCompany()
})

onDeactivated(() => {
    company.value = {}
})
</script>

<style scoped lang="scss">
.company {
    padding-bottom: 43px;

    &::v-deep(.v-breadcrumbs__item) {
        &:nth-child(2) {
            img {
                display: none;
            }
        }
    }

    &__wrapper {
        padding-top: 12px;
    }

    &__block-wrapper {
        width: 100%;
        margin-top: 24px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__block-menu {
        width: 256px;
        padding: 24px 16px;
        border-radius: 16px;
        background-color: #fff;
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);
    }

    &__block-menu-title {
        padding-left: 8px;
        color: $dark;
        font-size: 14px;
        font-weight: 600;
    }

    &__block-menu-btn {
        height: 32px;
        padding: 0 8px;
        margin-top: 16px;
        display: flex;
        align-items: center;
        color: $blue;
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;
        user-select: none;

        img {
            margin-right: 4px;
        }
    }

    &__employees {
        margin-top: 40px;
    }

    &__employees-header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__employees-title {
        font-size: 24px;
        span {
            margin-left: 4px;
            color: $gray;
            font-size: 16px;
            font-weight: 700;
        }
    }

    &__employees-new {
        height: 32px;
        padding: 0 8px;
        font-size: 14px;
        font-weight: 500;

        &:active {
            background-color: $light-gray-2 !important;
        }
    }

    &__employees-cards {
        margin-left: -32px;
        display: flex;
        flex-wrap: wrap;
    }

    &__employees-cards-empty {
        width: 100%;
        margin-top: 207px;
        margin-bottom: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    &__employees-cards-empty-title {
        font-size: 24px;
        font-weight: 600;
    }

    &__employees-cards-empty-description {
        margin-top: 12px;
        font-size: 16px;
        font-weight: 400;
    }

    &__employees-card {
        width: calc(33.333% - 32px);
        margin-top: 24px;
        margin-left: 32px;
    }
}
</style>
